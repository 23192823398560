<template>
  <div>
    <!--todo: поправить мета-->
    <vue-headful
      title="Опубликовать объявление | Меркурий онлайн"
      description="Опубликовать объявление"
    />
    <Detail/>
  </div>
</template>

<script>
import Detail from '@/components/views/GuestAdvert/Detail'

export default {
  name: 'GuestAdvert',
  components: { Detail }
}
</script>

<style scoped>

</style>
