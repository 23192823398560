<template>
  <v-row class="pb-0 black--text">
    <div
      class="body-2 pa-4 d-flex flex-column"
      style="max-width: 500px"
    >С 10.00 до 21.00 на указанный Вами номер перезвонит оператор сайта для подтверждения верности
      указанного номера, после чего объявление будет опубликовано
    </div>
    <v-col
      cols="12"
      class="d-flex flex-column pb-0"
    >
      <div
        style="width: 100%"
        class="d-flex align-self-end flex-row align-center caption"
      >
        <span class="text-h4 red--text mr-2">*</span>
        <p class="align-self-end">Номер телефона</p>
      </div>
      <v-text-field
        style="max-width: 370px"
        large
        placeholder="Укажите ваш телефон"
        outlined
        dense
        :value="maskedPhone"
        @input="handleInput($event)"
        v-mask="{mask: mask, unmaskedVar: 'clearValue'}"
        :error-messages="checkErrors(`phone.phone.phone`)"
        @focus="$event.target.select()"
      ></v-text-field>
    </v-col>
    <v-col
      v-show="false"
      cols="6"
    >
      <v-textarea
        outlined
        dense
        label="Комментарий"
        v-model="phone.description"
        rows="1"
      >
      </v-textarea>
    </v-col>
  </v-row>
</template>

<script>
import phoneInput from '@/components/mixins/phoneInput'
import validationErrors from '@/components/mixins/validationErrors'
import { minLength, required } from 'vuelidate/lib/validators'
import { deepEqual } from '@/components/utils/common'

export default {
  mixins: [phoneInput, validationErrors],
  name: 'AdditionPhoneDetail',
  validations: {
    phone: {
      phone: {
        phone: {
          required,
          minLength: minLength(11)
        }
      }
    }
  },
  watch: {
    phone: {
      handler (newVal) {
        if (newVal.phone.phone && !deepEqual(newVal, this.value)) {
          this.$v.phone.phone.phone.$touch()
          if (!this.$v.phone.phone.phone.$anyError) {
            this.$emit('input', this.phone)
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
