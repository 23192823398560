<template>
  <div class="section-main-100">
    <v-progress-circular
      v-if="isLoading"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <div v-else>
      <div v-if="!detail">
        Что-то пошло не так
      </div>
      <v-container v-else>
        <v-card class="elevation-0 mb-3 pa-3 pt-16 pb-16">
          <h1 class="mb-3">
            {{
              detail.id ? 'Объявление Id: ' + detail.id : 'РАЗМЕСТИТЬ ОБЪЯВЛЕНИЯ МОЖНО СОВЕРШЕННО  БЕСПЛАТНО  И БЕЗОПАСНО'
            }}
          </h1>
          <p
            style="max-width: 600px;"
            class="ma-0"
          >Каждую квартиру прежде чем опубликовать на сайте, мы проверяем на достоверность информации</p>
        </v-card>
        <v-row>
          <v-col cols="12">
            <v-card class="elevation-0 pa-3">
              <h1 class="mb-3">{{ detail.id ? 'Объявление Id: ' + detail.id : 'Новое объявление' }}</h1>
              <v-alert
                v-if="detail.is_published"
                border="left"
                type="success"
                elevation="2"
                dismissible
              >
                Ваше объявление успешно прошло модерацию и опубликовано
              </v-alert>
              <!--todo: сделать инфоблок о том, что при добавлении без регистрации невозможно будет отслеживать объявление и т.д.-->
              <BuildingSection
                v-model="detail.building"
                :service-desc.sync="detail.description_service"
                :default-desc.sync="detail.description_default"
              />
              <SellerInfo
                v-if="detail.advertPhones.length"
                :owner-status-advert.sync="detail.owner_status_local"
                :advert-phones="detail.advertPhones"
                @update:advertPhones="handleAdvertPhonesUpdate($event)"
              />
              <AdvertInfo
                :flat-type.sync="detail.flat_type"
                :room-count.sync="detail.room_count"
                :floor.sync="detail.floor"
                :area-common.sync="detail.area_common"
                :area-kitchen.sync="detail.area_kitchen"
                :area-living.sync="detail.area_living"
                :planing-type.sync="detail.planing_type"
                :flat-status.sync="detail.flat_status"
                :price.sync="detail.price"
                :description-public.sync="detail.description_public"
              />
              <!--              <Price
                              :price.sync="detail.price"
                              :error-messages="checkErrors('detail.price')"
                            />-->
              <ImageUploader
                v-model="detail.advertImages"
                url="/image/real"
                :max-photo-count="30"
                class="mb-6"
              />
              <Save
                :loading="isSavingData"
                @save="handleSave"
                @delete="handleDelete"
                :is-delete="false"
                text-save="Разместить объявление"
              />
            </v-card>
          </v-col>

          <v-col v-if="isSuccessMessage">
            <v-card elevation="0">
              <v-card-title
                class="text-uppercase font-weight-bold card-success-info"
                style="word-break: normal;"
              >
                Объявление получено И ОПУБЛИКУЕТСЯ НА САЙТ ПОСЛЕ ПОДТВЕРЖДЕНИЯ НАШИМ МЕНЕДЖЕРОМ, вскоре мы
                с Вами свяжемся для уточнения деталей
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import detail from '@/components/mixins/detail'
import formatters from '@/components/mixins/formatters'
import SellerInfo from '@/components/views/GuestAdvert/Detail/SellerInfo'
import ImageUploader from '@/components/views/Account/MyAdvert/Detail/ImageUploader'
import AdvertInfo from '@/components/views/Account/MyAdvert/Detail/AdvertInfo'
import Save from '@/components/views/Account/MyAdvert/Detail/Save'
import Price from '@/components/views/Account/MyAdvert/Detail/Price'
import BuildingSection from '@/components/views/Account/MyAdvert/Detail/BuildingSection'
import { minLength, minValue, required } from 'vuelidate/lib/validators'
import validationErrors from '@/components/mixins/validationErrors'
import { cleanClone } from '@/components/utils/common'
import AdvertPhone from '@/components/structures/advertPhone'

export default {
  mixins: [detail, formatters, validationErrors],
  name: 'Detail',
  components: { BuildingSection, Price, Save, AdvertInfo, ImageUploader, SellerInfo },
  data () {
    return {
      url: '/account',
      routeName: 'guestCreateAdvert',
      isSuccessMessage: false
    }
  },
  provide () {
    return {
      $v: this.$v
    }
  },
  validations: {
    detail: {
      flat_type: {
        // required
      },
      room_count: {
        // required,
        minValue: minValue(1)
      },
      floor: {
        // required,
        minValue: minValue(1)
      },
      area_common: {
        // required,
        minValue: minValue(1)
      },
      price: {
        // required,
        minValue: minValue(1)
      },
      advertPhones: {
        // required,
        $each: {
          phone: {
            phone: {
              required,
              minLength: minLength(11)
            }
          }
        }
      },
      building: {
        mainBuildingAddress: {
          building_number: {
            // required
          },
          street: {
            name: {
              // required
            }
          }
        }
      }
    }
  },
  methods: {
    afterResponse () {
      this.isSuccessMessage = true
    },
    handleAdvertPhonesUpdate (data) {
      this.detail.advertPhones = cleanClone(data)
    }
  },
  async created () {
    await this.fillDetail()
    this.detail.advertPhones.push(new AdvertPhone())
  }
}
</script>

<style>
  .card-success-info {
    padding: 40px;
  }
  @media (min-width: 800px) {
    .card-success-info {
      padding: 70px 100px;
    }
  }
</style>
