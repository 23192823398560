<template>
  <div>
    <AdditionPhoneDetail
      v-if="!isLoadingServiceData"
      :value="advertPhones[0]"
      @input="handlePhoneInput($event)"
      :mask="phoneMask"
    />
    <div
      class="mt-4 pl-3"
      v-if="false"
    >
      <div>
        <p class="subtitle-1">Укажите какое отношение Вы имеете к данному объекту</p>
      </div>
      <v-radio-group
        v-if="ownerStatusLocalItems"
        :value="ownerStatusAdvert"
        @change="handleOwnerStatusChange($event)"
        row
      >
        <v-radio
          v-for="status in ownerStatusLocalItems"
          :key="status.id"
          :label="status.value"
          :value="status.id"
        ></v-radio>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import phoneComputed from '@/components/mixins/phoneComputed'
import serviceDataComputed from '@/components/mixins/serviceDataComputed'
import AdditionPhoneDetail from '@/components/views/GuestAdvert/Detail/AdditionPhoneDetail'

export default {
  mixins: [formatters, phoneComputed, serviceDataComputed],
  name: 'SellerInfo',
  components: { AdditionPhoneDetail },
  props: {
    ownerStatusAdvert: Number,
    advertPhones: Array
  },
  computed: {
    isLoadingServiceData () {
      return !this.ownerStatusLocalItems
    }
  },
  watch: {
    isLoadingServiceData () {
      if (!this.ownerStatusAdvert) {
        this.handleOwnerStatusChange(this.ownerStatusLocalItems[0].id)
      }
    }
  },
  methods: {
    handleOwnerStatusChange (data) {
      this.$emit('update:ownerStatusAdvert', data)
    },
    handlePhoneInput (data) {
      this.$emit('update:advertPhones', [data])
    }
  },
  mounted () {
    if (!this.isLoadingServiceData) {
      this.handleOwnerStatusChange(this.ownerStatusLocalItems[0].id)
    }
  }
}
</script>

<style scoped>

</style>
